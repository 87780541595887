<template>
  <ContentSection>
    <!-- TITLE -->
    <Container class="justify-between gap-10 lg:flex">
      <h2
        v-if="data.heading"
        class="default-component-title"
        :class="data.link ? 'mb-3 xl:mb-8' : 'mb-8 sm:mb-10'"
        v-html="$replaceHtmlTags(data.heading)"
      />
      <AppLink
        v-if="data.link && data.link_text"
        class="mb-7 sm:text-md xl:mb-0"
        :to="data.link"
        :target="data.target?.value"
      >
        {{ data.link_text }}
      </AppLink>
    </Container>

    <!-- NEWS -->
    <AnimatedContainer>
      <ul class="hidden grid-cols-4 gap-6 px-12 xl:grid">
        <li
          v-for="article in composedNews"
          :key="article.id"
          :class="article.colSpan"
          class="slide-in-left"
        >
          <NewsCard :data="article" />
        </li>
      </ul>
    </AnimatedContainer>

    <!-- NEWS MOBILE -->
    <div class="flex xl:hidden">
      <Swiper
        class="max-w-full"
        :slides-per-view="1.5"
        :space-between="12"
        :slides-offset-before="24"
        :slides-offset-after="24"
        :breakpoints="breakpoints"
      >
        <SwiperSlide v-for="article in composedNews" :key="article.id">
          <NewsCard :data="article" />
        </SwiperSlide>
      </Swiper>
    </div>
  </ContentSection>
</template>

<script setup>
const props = defineProps({
  data: { type: Object, default: () => ({}) },
})

const pageId = inject('pageId', null)

const { locale } = useI18n()
const fetchedNews = ref(null)

const breakpoints = {
  640: {
    // sm
    slidesOffsetBefore: 40,
    slidesOffsetAfter: 40,
    spaceBetween: 20,
  },
}

// FUNCTIONS
async function fetchNews() {
  let payload = await useCachedGql({
    operation: 'News',
    variables: {
      sort: 'date desc',
      limit: 3,
      filter: {
        site: locale.value,
        id: { not: pageId },
        news_type: switchContainsOrIn(props.data?.restricted_news_types),
      },
    },
  })

  payload = payload.data.value?.entries

  if (!payload) {
    console.error('NewsTeaser: News could not be fetched')
    return
  }

  return payload.data
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max)
}

// COMPUTED
const news = computed(() => {
  if (props.data.selection.value === 'newest') {
    return fetchedNews.value || []
  }

  return props.data.news || []
})

const randomInt = getRandomInt(news.value.length)

const composedNews = computed(() => {
  // maximal three news
  // a video is col-span-2
  // maximal one article is col-span-2, all others are col-span-1 (even if there are two videos)
  // if there are only three articles, one of them is random col-span-2

  let newsComposed = []
  let spanCounter = 0
  let maxOneVideoReached = false
  const newsLength = news.value.length

  for (let index = 0; index < newsLength; index++) {
    const article = JSON.parse(JSON.stringify(news.value[index]))
    const isVideo = !!article.image_or_video[0]?.video

    if (isVideo && !maxOneVideoReached && spanCounter < 3) {
      article.colSpan = 'col-span-2'
      spanCounter += 2
      newsComposed.push(article)
      maxOneVideoReached = true
      continue
    }

    if (spanCounter < 4) {
      article.colSpan = 'col-span-1'
      spanCounter += 1
      newsComposed.push(article)
    }

    if (index === news.value.length - 1 && spanCounter < 4) {
      newsComposed[randomInt].colSpan = 'col-span-2'
    }
  }

  return newsComposed
})

// WATCHERS
watch(
  () => props.data,
  async (newValue) => {
    if (newValue.selection?.value === 'newest') {
      fetchedNews.value = await fetchNews()
    }
  },
  { deep: true }
)

// INITIAL
if (props.data.selection?.value === 'newest') {
  fetchedNews.value = await fetchNews()
}
</script>
